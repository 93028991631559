import React from "react"
import { graphql } from "gatsby"

import Sidebar from '../components/sidebar';
import { DateTime } from 'luxon';
import {htmlDirectives} from '../../utils';
import { Helmet } from "react-helmet"

const directive = require('remark-directive')
var remark2rehype = require('remark-rehype')
var format = require('rehype-format')
var stringify = require('rehype-stringify')
var parse = require('remark-parse')
var unified = require('unified')



export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { contentfulPost } = data // data.markdownRemark holds your post data

  const post = unified()
    .use(parse)
    .use(directive)
    .use(htmlDirectives)
    .use(remark2rehype)
    .use(format)
    .use(stringify)
    .processSync(contentfulPost.body.body)

  return (
    <Sidebar>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{contentfulPost.title}</title>
      </Helmet>
      <div className="blog-post-container">
        <div className="blog-post">
          <article className="prose dark:prose-light pb-4">
            <h1>{contentfulPost.title}</h1>
            <div>Posted on {DateTime.fromISO(contentfulPost.createdDate).toFormat('MMMM d y')}</div>
            <div dangerouslySetInnerHTML={{__html: post}}></div>
          </article>
        </div>
      </div>
    </Sidebar>   
  )
}

export const pageQuery = graphql`
  query($path: String!) {
    contentfulPost(path:{eq:$path}){
      id
      path
      title
      createdDate
      body{
        body
      }
    }
  }
  
`